import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import store from '../store/index.js'
const routes = [{
	path: "/",
	name: "login",
	component: () => import("../page/login.vue"),
}, {
	path: "/home",
	name: "home",
	component: () => import("../page/home.vue"),
	children: [{
		path: '',
		name: "/chartPage",
		meta: {
			title: "首页",
		},
		component: () =>
			import("@/page/model/chartPage.vue")
	}]
}];


const router = createRouter({
	history: createWebHashHistory(),
	routes,
});


if(localStorage.menus){
	let menus = JSON.parse(localStorage.getItem("menus"))
	for (let i = 0; i < menus.length; i++) {
		router.options.routes[1].children.push({
			path: menus[i].path,
			name: menus[i].title,
			meta: {
				title: menus[i].title,
			},
			component:() => import(`@/page${menus[i].url}`)
		});
	}
	let roles = router.options.routes
	for (let i = 0; i < roles.length; i++) {
		router.addRoute(roles[i])
	}
}


router.beforeEach((to, from, next) => {
	let tab = store.state.tab
	if (from.path != "/" || to.path != "/") {
		let user = store.state.user
		if (user) {
			var route = {
				name : to.name,
				title : to.meta.title,
				href : to.fullPath
			}
			let isHas = false
			if(to.meta.title != "首页"){
				for(let i = 0 ; i < tab.length ; i ++){
					if(tab[i].name == route.name){
						isHas = true
						break
					}
				}
				if(!isHas){
					tab.push(route)
					store.commit("SET_TAB",tab)
				}
			}
			next()
		} else {
			if (to.path != "/") {
				next({
					path: '/'
				})
			}
		}
	} else {
		next()
	}
});

export default router;
