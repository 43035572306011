import axios from 'axios'
import store from '../store/index.js'
// import { Message } from 'element-ui';
const service = axios.create({
	timeout: 30000,
	withCredentials: true,
	//baseURL: "http://127.0.0.1:8878"
	baseURL: "http://82.156.178.62:8878"
})

// service.interceptors.response.use(function(response) {
//   if(response.data.code == 415){
//     Message.error('登录过期');
//     window.location.href = "/login"
//   }else{
//     //Message.success('操作成功');
//   }
// 	return response
// }, function(error) {
//    Message.error('接口异常');
// })

service.interceptors.request.use(
	config => {
		if (store.state.user.token) {
			config.headers['token'] = store.state.user.token
		} else {
			var path = config.url
			if (path != "/user/login") {
				window.location.href = "/login"
			}
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

export default service
