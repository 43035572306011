import request from '../util/requset.js'

export function login(params){
	return request.post("/user/login",params)
}

export function addUser(data){
	return request.post("/user/register",data)
}

export function getUserByMerchant(merchantId,start,end){
	return request.get("/user/getAllUserByMerchant",{
		params:{
			merchantId:merchantId,
			start:start,
			end:end
		}
	})
}