<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		created() {
			// 在页面加载时读取sessionStorage
			if (sessionStorage.getItem('store')) {
				var data = JSON.parse(sessionStorage.getItem('store'))
				this.$store.replaceState(Object.assign({}, this.$store.state, data))
			}

			// 在页面刷新时将store保存到sessionStorage里
			window.addEventListener('beforeunload', () => {
				sessionStorage.removeItem('store')
				sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			})
		},
	}
</script>

<style>
	.tableHeader {
		background-color: rgb(242, 242, 242) !important;
	}

	.el-table td,
	.el-table th {
		padding: 6px 0 !important;
	}
	
	.myLabel{
		width: 80px;
	}

	body {
		margin: 0;
		padding: 0;
	}

	a {
		color: black;
		text-decoration: none;
	}

	footer {
		height: 300px;
		background-color: rgb(44, 45, 45);
	}

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 10px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 10px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgb(198, 200, 201);
	}


	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background: #ededed;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgb(184, 185, 187);
	}

	::-webkit-scrollbar-thumb:active {
		background: rgb(184, 185, 187);
	}

	.slide-left-enter {
		transform: translate(100%, 0);
	}

	.slide-left-leave-active {
		transform: translate(-50%, 0);
	}

	.slide-right-enter {
		transform: translate(-50%, 0);
	}

	.slide-right-leave-active {
		transform: translate(100%, 0);
	}
	
	.el-tabs__content{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
	
	.el-tab-pane{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
</style>
