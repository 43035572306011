import {
	createStore
} from 'vuex'
import {
	login
} from '../api/user.js'
import router from '../router/index.js'

export default createStore({
	state: {
		user: {},
		tab: []
	},
	mutations: {
		SET_USER: (state, userInfo) => {
			state.user = userInfo
		},

		SET_TAB: (state, routeArr) => {
			state.tab = routeArr
		}
	},
	actions: {
		Login({
			commit
		}, userInfo) {
			const username = userInfo.username.trim()
			const password = userInfo.password
			return new Promise((resolve, reject) => {
				login({
					username: username,
					password: password
				}).then(res => {
					if (res.data.data != null) {
						var data = res.data.data
						commit('SET_USER', data)
						let menus = data.role.menus
						localStorage.setItem("menus", JSON.stringify(menus))
						for (let i = 0; i < menus.length; i++) {
							router.options.routes[1].children.push({
								path: menus[i].path,
								name: menus[i].title,
								meta: {
									title: menus[i].title,
								},
								component:() => import(`@/page${menus[i].url}`)
							});
						}
						let roles = router.options.routes
						for (let i = 0; i < roles.length; i++) {
							router.addRoute(roles[i])
						}
						resolve(true)
					} else {
						resolve(false)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
	}
})
